<template>
    <div class="organization_register_expired">
        <div class="organization_register_expired__body">
            <div class="mw-1000">
                <div class="organization_register_expired__body__form">
                    <form class="horizontal">
                        <div
                            class="organization_register_expired__body__form-infomation"
                        >
                            一週間以上経過したため、再度担当者招待から実施してください
                        </div>
                        <div class="btn_group">
                            <button
                                @click="goTop"
                                type="button"
                                class="btn-md btn-blue shadow"
                            >
                                TOPへ戻る
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/register-invited/expired/Expired.ts"></script>

<style lang="scss">
@import "@/presentation/views/organization/register-invited/expired/Expired.scss";
</style>
